import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import FuncFetch from '../../Utilities/funcFetch'
import { useMsal } from '@azure/msal-react'

function EditNoteModal({
    show, 
    setShow,
    note,
    updateNCDAndActivities
}) {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const { REACT_APP_API_URL } = process.env;
    const { instance, accounts } = useMsal();

    const handlePatchNote = () => { 
        setLoading(true)
        FuncFetch(`${REACT_APP_API_URL}/Claims/DeleteNote`,{activityID:note.activityID},'DELETE',null,instance,accounts)
        .then(response=>{
            setLoading(false)
            setSuccess(true)
            updateNCDAndActivities({activities:response})
        })
        .catch(error=>{
            setLoading(false)
            setError(true)
            console.error(error)
        })
     }

  return (
    <Modal show={show} onHide={setShow}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h4>Are you sure you want to delete this note?</h4>
            <div className='note-details'>
                <span style={{ marginRight: '5px', color: '#51adf4' }}>{note.collector}</span>
                <span style={{ marginLeft: '5px', color: '#51adf4' }}>{note.time&&new Date(note.time).toLocaleString()}</span>
                <p className='allow-white-spacing'>{note.comments}</p>
            </div>
        </Modal.Body>
        <Modal.Footer className='full-width'>
            <Button variant="secondary" onClick={()=>setShow(false)} className='half-width'>
                Close
            </Button>
            {success?
                <Alert className='half-width'>Successfully deleted.</Alert>
            :
                <Button variant="danger"className='half-width' onClick={()=>handlePatchNote()}>
                    {loading?
                        <Spinner animation='border' size='sm'/>
                    :
                        'Delete'
                    }
                </Button>
            }
            {error?
                <Alert variant='warning'>There was an issue, please try again.</Alert>
            :null}
        </Modal.Footer>
      </Modal>
  )
}

export default EditNoteModal