import React, { useEffect, useState } from 'react'
import AccountDetailsPopupView from './AccountDetailsPopupView'
import useFetchGet from '../Utilities/useFetchGet';
import './AccountDetails.css'

function AccountDetailsPopup({ show, setShow, selectedRow }) {
  const { REACT_APP_API_URL } = process.env;
  const [accountDetails, setAccountDetails] = useState();
  const [adverseCarrier, setAdverseCarrier] = useState();
  const [activities, setActivities] = useState();
  const [paymentHistory, setPaymentHistory] = useState();
  const [phones, setPhones] = useState();
  const [emails, setEmails] = useState();
  const [statusCode, setstatusCode] = useState();
  const [accountBalance, setAccountBalance] = useState();
  const [financial, setFinancial] = useState();
  const { data: accountDetailsArray } = useFetchGet(`${REACT_APP_API_URL}/Claims/GetAccountDetail`, {debtorId:selectedRow.debtorId})

  useEffect(() => {
    if (accountDetailsArray) {

      setAccountDetails(accountDetailsArray.account);
      setActivities(accountDetailsArray.activities);
      setAdverseCarrier(accountDetailsArray.adverseCarrier);
      setstatusCode(accountDetailsArray.statusCode);
      setPaymentHistory(accountDetailsArray.paymentHistory[0] != null ? accountDetailsArray.paymentHistory : '');
      setAccountBalance(accountDetailsArray.accountBalance);
      setFinancial(accountDetailsArray.financial ? accountDetailsArray.financial : []);
      setPhones(accountDetailsArray.phones)
      setEmails(accountDetailsArray.emailAddresses)
    }
  }, [accountDetailsArray])
  return (
    <AccountDetailsPopupView
      show={show}
      setShow={setShow}
      accountDetails={accountDetails}
      activities={activities}
      setActivities={setActivities}
      adverseCarrier={adverseCarrier}
      paymentHistory={paymentHistory}
      accountBalance={accountBalance}
      statusCode={statusCode}
      financial={financial}
      phones={phones}
      setPhones={setPhones}
      emails={emails}
      setEmails={setEmails}
    ></AccountDetailsPopupView>
  )
}

export default AccountDetailsPopup