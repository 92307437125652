import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import FuncFetch from '../../Utilities/funcFetch'
import { useMsal } from '@azure/msal-react'

function EditNoteModal({
    show, 
    setShow,
    note,
    updateNCDAndActivities
}) {
    const [editedNote, setEditedNote] = useState(note.comments)
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const { REACT_APP_API_URL } = process.env;
    const { instance, accounts } = useMsal();
    
    useEffect(()=>{
        if(success||error){
            setSuccess(false);
            setError(false);
        }
    // eslint-disable-next-line
    },[editedNote])

    const handlePatchNote = () => { 
        setLoading(true)
        FuncFetch(`${REACT_APP_API_URL}/Claims/UpdateNote`,null,'PATCH',{...note,comments:editedNote},instance,accounts)
        .then(response=>{
            setLoading(false)
            setSuccess(true)
            updateNCDAndActivities({activities:response})
        })
        .catch(error=>{
            setLoading(false)
            setError(true)
            console.error(error)
        })
     }

  return (
    <Modal show={show} onHide={setShow}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Label>Note:</Form.Label>
            <Form.Control as='textarea' className='allow-white-spacing height30vh' value={editedNote} onChange={(e)=>setEditedNote(e.target.value)} />
            
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={()=>setShow(false)}>
                Close
            </Button>
            <Button variant="primary" onClick={()=>handlePatchNote()}>
                {loading?
                <Spinner animation='border' size='sm'/>
                :
                'Save Changes'
                }
            </Button>
            {success?
            <Alert>Successfully saved!</Alert>
            :null}
            {error?
            <Alert variant='warning'>There was an issue, please try again.</Alert>
            :null}
        </Modal.Footer>
      </Modal>
  )
}

export default EditNoteModal