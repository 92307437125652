import React, { useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "react-bootstrap";
import CustomerContext from "./Auth/CustomerContext";

/**
 * Renders a sign out button 
 */
export const SignOutButton = () => {
  const { instance } = useMsal();
  const {cookies} = useContext(CustomerContext)

  const handleLogout = (logoutType) => {
    cookies.remove('customer')
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };

  return (
      <Button variant="light" onClick={() => handleLogout("redirect")}>
        Sign out
      </Button>
  );
};