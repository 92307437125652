import { useState, useEffect, useContext } from 'react'
import { useMsal } from '@azure/msal-react';
import CustomerContext from '../Auth/CustomerContext';
import _ from 'lodash'

function useFetchGet(baseUrl, params) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [data, setData] = useState();
    const [status, setStatus] = useState();
    const [statefulParams, setStatefulParams] = useState();
    const { instance, accounts } = useMsal();
    const { cookies } = useContext(CustomerContext)

    useEffect(()=>{
        // Check to see if there is a new param object without infinite reloading
        if(!(_.isEqual(statefulParams,params))){
            setStatefulParams({...params})
        }
    // eslint-disable-next-line
    },[params])

    useEffect(()=>{
        if(baseUrl&&_.isEqual(statefulParams,params)){
            const buildURLAndFetch = () => { 
                let stringifiedParams = `?Token=${cookies.get('customer')}`
                if(statefulParams){
                    let statefulParamsEntries = Object.entries(statefulParams)
                    statefulParamsEntries.forEach((param) => {
                            stringifiedParams+=`&${param[0]}=${param[1]}`
                    });
                }
                const handleLogout = () => {
                    instance.logoutRedirect({postLogoutRedirectUri: '/login'})
                      .catch(e => {
                        console.error("Logout failed:", e);
                      });
                  };
                instance
                .acquireTokenSilent({
                    scopes: ["api://0545cd28-732c-4262-9c0e-bdbf335e2b6c/User.Read"],
                    account: accounts[0]
                })
                .then((response) => {
                    fetchData((baseUrl+stringifiedParams), response.accessToken)
                })
                .catch((error)=>{
                    console.error('There was an issue acquiring the silent token',error)
                    cookies.remove('customer')
                    handleLogout()
                });;
             }
            buildURLAndFetch()
        }
    // eslint-disable-next-line
    },[baseUrl,statefulParams])

     const fetchData = (url, accessToken) => { 
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
    
        headers.append("Authorization", bearer);
    
        const options = {
            method: "GET",
            headers: headers
        };
        setLoading(true)
        fetch(url, options)
        .then(res=>{
            setStatus(res.status)
            if(res.ok){
                return res.json()
            }else if(res.status===401){
                cookies.remove('customer')
                window.location.reload();
            }else{
                throw Error(res)
            }
        })
        .then(data=>{
            setLoading(false)
            setData(data)
        })
        .catch(err=>{
            setLoading(false)
            setError(err)
            console.error(err)
        })
      }

  return {loading, error, data, status}
}

export default useFetchGet