import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useContext, useEffect } from "react";
import CustomerContext from "./CustomerContext";
import FuncFetch from '../Utilities/funcFetch'

function ProtectedRoute({children}) {
  const isAuthenticated = useIsAuthenticated();
  const { cookies } = useContext(CustomerContext)
  const { REACT_APP_API_URL } = process.env;
  const { instance, accounts } = useMsal();

  useEffect(() => {
    if((accounts.length===0)&&cookies.get('customer')){
      cookies.remove('customer')
      window.location.reload(true);
    }
    // eslint-disable-next-line
  }, [isAuthenticated])

  useEffect(() => {
    if(!cookies.get('customer')&&(accounts.length>0)){
      FuncFetch(`${REACT_APP_API_URL}/Authentication/Login`,null,'GET',null,instance,accounts)
      .then(data=>{
        cookies.set('customer',data)
      })
      .catch(err=>{
        console.error(err)
      })
    }
    // eslint-disable-next-line
  },[])

  return (isAuthenticated ? children : <></>)
}

export default ProtectedRoute