import React from 'react'
import './ClaimLookup.css'

const FormRow = ({children, variant, className}) => {
    return (
        <div className={`form-row ${variant==='sm'?'form-row-small':''} ${className?className:''}`}>
            {children}
        </div>
    );
}
 
export default FormRow;