import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import CollectorDropdown from '../CollectorDropdown';

const AssignClaimPopup = ({
    show,
    setShow,
    collector,
    debtorID,
    collectorOptions,
}) => {

    return (
        <div>
            <Modal show={show} onHide={setShow}>
                <Modal.Header closeButton>
                    <Modal.Title>Assign Claim</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CollectorDropdown
                        collector={collector}
                        collectorOptions={collectorOptions}
                        readOnly={false}
                        debtorID={debtorID}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>setShow(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AssignClaimPopup;