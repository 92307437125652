import React from 'react'
import './ClaimLookup.css'

const FormCol = ({children, variant, className}) => {
    return (
        <div className={`form-col ${variant==='sm'?'form-col-small':''} ${className?className:''}`}>
            {children}
        </div>
    );
}
 
export default FormCol;