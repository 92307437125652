import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import useFetch from '../../../Utilities/useFetch';
import ReactSelect from 'react-select'

function AddEmailPopup({ debtorID, show, setShow, setEmails }) {
  const [email, setEmail] = useState();
  const [error, setError] = useState();
  const [paramsObj, setParamsObj] = useState();
  const [postObj] = useState({})
  const [selectedAccountHolder,setSelectedAccountHolder] = useState();
  const [accountHolderOptions] = useState([
    {value:1,label:'CL'},
    {value:2,label:'RO'},
    {value:3,label:'Other'}
  ]);
  const { REACT_APP_API_URL } = process.env;
  const { data:newEmailsList, status, loading, error: addEmailError } = useFetch(paramsObj,`${REACT_APP_API_URL}/Claims/AddEmailAddress`,'POST', postObj, paramsObj);

  useEffect(()=>{
    if(status){
      setParamsObj(false);
      if(addEmailError){
        setError('There was an error, please try again')
      }
    }
    //eslint-disable-next-line
  },[status])

  useEffect(()=>{
    if(newEmailsList&&status===200){
      setEmails(newEmailsList)
    }
    //eslint-disable-next-line
  },[newEmailsList])

  const postNewEmail = () => { 
    if(validateEmail(email)){
      setParamsObj({DebtorID:debtorID,EmailAddress:email,AccountHolder:selectedAccountHolder.value})
    }else{
      setError("Email is invalid")
    }
   }

  const handleEmailUpdate = (newNumber) => { 
    setError(null)
    setEmail(newNumber)
   }
   const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <Modal show={show} onHide={setShow}>
      <Modal.Header closeButton>
        <h4>Add New Email</h4>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
              <Form.Label>Email:</Form.Label>
              <Form.Control value={email} onChange={(e) => handleEmailUpdate(e.target.value)} />
              <Form.Label>Account Holder</Form.Label>
              <ReactSelect 
                options={accountHolderOptions}
                onChange={(e)=>setSelectedAccountHolder(e)}
                value={selectedAccountHolder}
              />
          </Form.Group>
          {error&&
            <Alert variant='warning'>{error}</Alert>
          }
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {(status===200)?
          <Alert className='full-width'>Successfully added new email</Alert>
        :
          <Button className='full-width' onClick={()=>postNewEmail()}>{loading?<Spinner animation='border' size='sm'/>:'Submit'}</Button>
        }
        <Button className='full-width' variant='secondary' onClick={()=>setShow(false)}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddEmailPopup