import React, { useEffect, useState } from 'react'
import ReactSelect from 'react-select'

function AdverseCarrierDropdown({
    adverseCarriers,
    selectedAdverseCarrier,
    setSelectedAdverseCarrier
}) {

const [carrierOptions, setCarrierOptions] = useState();

useEffect(()=>{
    if(adverseCarriers?.length>0){
        let newOptions = adverseCarriers.map(carrier=>{
            return {value:carrier, label:carrier.adverseCarrier+' '+carrier.adverseCarrierID}
        })
        setSelectedAdverseCarrier(newOptions[0])
        setCarrierOptions(newOptions)
    }
// eslint-disable-next-line
},[adverseCarriers])

  return (
    <ReactSelect
        value={selectedAdverseCarrier}
        options={carrierOptions}
        onChange={(e)=>setSelectedAdverseCarrier(e)}
    />
  )
}

export default AdverseCarrierDropdown