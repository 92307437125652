import React, { useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import FuncFetch from '../Utilities/funcFetch'
import { useMsal } from '@azure/msal-react';
import { Spinner } from 'react-bootstrap';

function SIFAuthButton({
    debtorID,
    sifauth,
    updateNCDAndActivities
}) {
    const { REACT_APP_API_URL } = process.env;
    const { instance, accounts } = useMsal();
    const [isAuthed, setIsAuthed] = useState(sifauth)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState();
    const [successfullyPosted, setSuccessfullyPosted] = useState(false);

    const callSIFAuthAPI = (checked) => { 
        setSuccessfullyPosted(false);
        setError(null);
        setLoading(true)
        FuncFetch(`${REACT_APP_API_URL}/Claims/PostSIFFlag`,{debtorID},'POST',null,instance,accounts)
        .then(response=>{
            setSuccessfullyPosted(true)
            setIsAuthed(checked)
            setLoading(false)
            updateNCDAndActivities({activities:response})
        })
        .catch(error=>{
            setError('There was an error, please try again')
            setLoading(false)
            console.error(error)
        })
     }
  return (
    <>
    {loading?
        <Spinner size='sm' animation='border' />
    :
        <Form.Check checked={isAuthed} onChange={(e)=>callSIFAuthAPI(e.target.checked)} />
    }
    {error?
        <Alert variant='warning'>{error}</Alert>
    :successfullyPosted?
        <Alert>Successfully updated!</Alert>
    :null}
    </>
  )
}

export default SIFAuthButton