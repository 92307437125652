import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import FuncFetch from '../../Utilities/funcFetch'
import { useMsal } from '@azure/msal-react'
import { MdEmail } from 'react-icons/md'
import { Alert, ButtonGroup } from 'react-bootstrap'
import { PiPencil } from 'react-icons/pi'
import { BiTrash } from 'react-icons/bi'
import EditNoteModal from './EditNoteModal'
import DeleteNoteConfirmationModal from './DeleteNoteConfirmationModal'

function Comments({ 
    activities,
    setActivities, 
    debtorID,
    updateNCDAndActivities
    }) {
    const [loader, setLoader] = useState(false);
    const [activityList, setActivityList] = useState(activities);
    const [searchString, setSearchString] = useState();
    const [error, setError] = useState();
    const [notes, setNotes] = useState('');
    const [selectedNote, setSelectedNote] = useState();
    const [editNotePopupOpen,setEditNotePopupOpen] = useState(false);
    const [deleteNoteConfirmationPopupOpen, setDeleteNoteConfirmationPopupOpen] = useState(false);
    const { REACT_APP_API_URL } = process.env;
    const { instance, accounts } = useMsal();

    useEffect(()=>{
        if(searchString&&searchString!==''){
            let newListToDisplay = []
            activities.forEach(activity=>{
                if(activity.comments.toUpperCase().includes(searchString.toUpperCase())){
                    newListToDisplay.push(activity)
                }
            })
            setActivityList(newListToDisplay)
        }else if(searchString===''){
            setActivityList(activities)
        }
    // eslint-disable-next-line
    },[searchString])

    useEffect(()=>{
        if(activities){
            setActivityList(activities)
        }
    },[activities])

    const handleSubmit = (event) => {
        event.preventDefault();

        setLoader(true);
        if (notes === '') {
            setLoader(false);
        } else {
            FuncFetch(
                `${REACT_APP_API_URL}/Claims/PostClaimNotes`,
                {debtorID,note:encodeURIComponent(notes)},
                'POST',
                '{}',
                instance,
                accounts
                )
                .then((json) => {
                    setNotes('');
                    setActivityList(json)
                    setActivities(json)
                    setLoader(false);
                })
                .catch(err=>{
                    setNotes('')
                    setLoader(false)
                    setError('There was an issue, please try again',err.message)
                    console.error('There was an issue, please try again',err)
                })
        }
    }

    const openEmail = (mailto, subject, body) => { 
        // This replaces all the line breaks with the proper line break format for email
        body = body.replace(/(?:\r\n|\r|\n)/g, '%0D%0A');
        // This obviously opens the default mailer
        window.open(`mailto:${mailto}?subject=${subject}&body=${body}`)
     }

    function handleResize(e) {
        if(error){
            setError(null)
        }
        e.target.style.height = ""; /* Reset the height*/
        e.target.style.height = Math.min(e.target.scrollHeight, 200) + "px";
      };

    const handleOpenNoteEditPopup = (note) => { 
        setSelectedNote(note)
        setEditNotePopupOpen(true)
     }
    
    const handleOpenDeleteConfirmationPopup = (note) => {
        setSelectedNote(note)
        setDeleteNoteConfirmationPopupOpen(true)
    }

    return (
        <div>
            {editNotePopupOpen&&<EditNoteModal show={editNotePopupOpen} setShow={setEditNotePopupOpen} note={selectedNote} updateNCDAndActivities={updateNCDAndActivities} />}
            {deleteNoteConfirmationPopupOpen&&<DeleteNoteConfirmationModal show={deleteNoteConfirmationPopupOpen} setShow={setDeleteNoteConfirmationPopupOpen} note={selectedNote} updateNCDAndActivities={updateNCDAndActivities} />}
            <Form.Control placeholder='Search...' onChange={(e)=>setSearchString(e.target.value)}></Form.Control>

            <div className='notes-wrapper padding card margin-top'>
                {activityList.map((item) => (
                <div key={item.activityID} className='note'>
                    <div className='note-details'>
                        <span style={{ marginRight: '5px', color: '#51adf4' }}>{item.fullName}</span>
                        <span style={{ marginLeft: '5px', color: '#51adf4' }}>{item.time&&new Date(item.time).toLocaleString()}</span>
                        <p className='allow-white-spacing'>{item.comments}</p>
                    </div>
                    <ButtonGroup vertical className='note-buttons'>
                        <Button variant='outline-success' onClick={()=>openEmail('',`Claim Note Review Request: ${debtorID}`,item.comments)}><MdEmail/></Button>
                        {(accounts[0].username.split('@')[0].trim()===item.collector.trim())?
                            <>
                            <Button variant='outline-primary' onClick={()=>handleOpenNoteEditPopup(item)}><PiPencil/></Button> 
                            <Button variant='outline-danger' onClick={()=>handleOpenDeleteConfirmationPopup(item)}><BiTrash/></Button>
                            </>
                        :null}
                    </ButtonGroup>
                </div>))}
            </div>
            
            <Form className='margin-top' onSubmit={(e)=>handleSubmit(e)}>
                <Row>
                    <Col sm={10}>
                        <Form.Control 
                            as='textarea' 
                            placeholder='Add Note...' 
                            value={notes} 
                            onChange={(e) => setNotes(e.target.value)} 
                            onInput={(e)=>handleResize(e)}
                        />
                    </Col>
                    <Col sm={2}>
                        <Button className='full-width' type="submit" disabled={loader} >{loader ? 'Loading...' : 'Submit'}</Button>
                    </Col>
                </Row>
                {error?
                    <Alert variant='warning'>{error}</Alert>
                :null}
            </Form>
        </div>
    )
}

export default Comments
