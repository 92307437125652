function FuncFetch( baseUrl, params, method, postObj, instance, accounts){ 
    let stringifiedParams = ''
    if(params){
        let paramsEntries = Object.entries(params)
        paramsEntries.forEach((param,index) => {
            if(index===0){
                stringifiedParams+=`?${param[0]}=${param[1]}`
            }else{
                stringifiedParams+=`&${param[0]}=${param[1]}`
            }
        });
    }
    return instance
    .acquireTokenSilent({
        scopes: ["api://0545cd28-732c-4262-9c0e-bdbf335e2b6c/User.Read"],
        account: accounts[0]
    })
    .then((response) => {
        const headers = new Headers();
        const bearer = `Bearer ${response.accessToken}`;
    
        headers.append("Authorization", bearer);
        headers.append("Content-Type","application/json")
    
        const options = {
            method: method,
            headers: headers,
        };

        if(postObj){
            options.body = JSON.stringify(postObj)
        }
    
        return fetch((baseUrl+stringifiedParams), options)
        .then(res=>{
            if(res.ok){
                return res.json()
            }else{
                console.error(res)
                throw Error(res)
            }
        })
        .then(data=>{
            return data
        })
        .catch(err=>{
            console.error(err)
            throw err
        })
    })
    .catch((error)=>{
        console.error(error)
        throw error
    });
 }

export default FuncFetch;