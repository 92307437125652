import ReactSelect from 'react-select'
import useFetch from '../../Utilities/useFetch'
import { useEffect } from 'react';
import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import FuncFetch from '../../Utilities/funcFetch'
import { useMsal } from '@azure/msal-react'
import './SearchForm.css'
import SaveSearchModal from './SaveSearchModal';

export const AdvancedSearch = ({
    setClaims
}) => { 
    const [fieldOptions, setFieldOptions] = useState();
    const numberOptions = [        
        {
            "value": "Equal",
            "label": "Equal"
        },
        {
            "value": "NotEqual",
            "label": "Not Equal"
        },
        {
            "value": "GreaterThan",
            "label": "Greater Than"
        },
        {
            "value": "LessThan",
            "label": "Less Than"
        },
        {
            "value": "GreaterThanOrEqual",
            "label": "Greater Than Or Equal"
        },
        {
            "value": "LessThanOrEqual",
            "label": "Less Than Or Equal"
        }
        
    ]
    const dateOptions = [
        {
            "value": "Equal",
            "label": "Equal"
        },
        {
            "value": "GreaterThan",
            "label": "Greater Than"
        },
        {
            "value": "LessThan",
            "label": "Less Than"
        }
    ]
    const booleanOptions = [
        {
            "value": "Equal",
            "label": "Equal"
        }
    ]
    const stringOptions = [
        {
            "value": "Equal",
            "label": "Equal"
        },
        {
            "value": "Contains",
            "label": "Contains"
        },
        {
            "value": "NotContains",
            "label": "NotContains"
        }

    ]
    const [saveSearchModalIsOpen,setSaveSearchModalIsOpen] = useState(false);
    const [postObj, setPostObj] = useState([])
    const [newRule, setNewRule] = useState({
            searchCondition: "AND"
    });
    const { instance, accounts } = useMsal();    
    const { REACT_APP_API_URL } = process.env;
    const [dynamicDropdownValue, setDynamicDropdownValue] = useState();
    const [dynamicValueOptions,setDynamicValueOptions] = useState();
    const [searchLoading, setSearchLoading] = useState(false);
    const { data: searchFields } = useFetch(true,`${REACT_APP_API_URL}/Claims/GetSearchFields`)
    const { data: savedSearches, loading: loadingSavedSearches } = useFetch(true, `${REACT_APP_API_URL}/Claims/GetSavedSearches`)

    useEffect(()=>{
        if(searchFields){
            let searchFieldsAsOptions = searchFields.map(fieldObj=>{
                let option = {value:fieldObj,label:fieldObj.field}
                return option
            })
            setFieldOptions(searchFieldsAsOptions)
        }
    },[searchFields])

    const handleAddRule = () => { 
        newRule.searchField = newRule.searchField.field
        setPostObj([...postObj,newRule])
        let blankNewRule = {
            searchCondition: "AND"
        }
        setNewRule(blankNewRule)
        setDynamicDropdownValue(null)
     }

     const populateSavedSearch = (base64Search) => { 
        setPostObj(JSON.parse(window.atob(base64Search)))
      }

    // When user selects a new Field we dynamically grab the Operators and Values
    const handleChange = (e, fieldName) => {
        let newRuleObj = {...newRule}
        if(fieldName==='searchValue'){
            newRuleObj.searchValue = e.target.value;
        }else if(fieldName==='selectSearchValue'){
            newRuleObj.searchValue = e.value
        }else if(fieldName==='searchField'){
            setDynamicDropdownValue(null)
            newRuleObj = {
                searchField: e.value,
                searchCondition: 'AND'
            }
            if(e.value.lookup!=='None'){
                //If there is a lookup listed we can grab the Values from the API by passing the lookup string to the URL
                let apiEndpoint = e.value.lookup.split('(')[0]
                FuncFetch(`${REACT_APP_API_URL}/Claims/${apiEndpoint}`,null,'GET',null,instance,accounts)
                .then(response=>{
                    setDynamicValueOptions(response.map(obj=>{
                        return {value:obj.key,label:obj.value}
                    }))
                })
            }
        }else if(fieldName==='searchValueSelect'){
            setDynamicDropdownValue(e)
            newRuleObj.searchValue = e.value;
        }else{
            newRuleObj[fieldName] = e.value;
        }
        setNewRule(newRuleObj)
    }

    const removeRule = (index) => { 
        let newPostObj = [...postObj]
        newPostObj.splice(index,1)
        setPostObj(newPostObj)
     }

    const submitRulesToSearch = () => { 
        setSearchLoading(true)
        FuncFetch(`${REACT_APP_API_URL}/Claims/AdvancedSearch`,null,'POST',{"searchItems":postObj},instance,accounts)
        .then(response=>{
            setSearchLoading(false)
            setClaims(response)
        })
     }

    return(
        <>
        {saveSearchModalIsOpen?<SaveSearchModal searchParams={postObj} show={saveSearchModalIsOpen} setShow={setSaveSearchModalIsOpen} />:null}
        <Row className='advanced-search-rules-selector'>
            <Col>
                <Form.Label>Field</Form.Label>
                <ReactSelect 
                    options={fieldOptions} 
                    value={newRule.searchField?{value:newRule.searchField,label:newRule.searchField.field}:null} 
                    onChange={(e)=>handleChange(e,'searchField')} 
                />
            </Col>
            <Col>
                <Form.Label>Operator</Form.Label>
                <ReactSelect 
                    options={
                        (newRule.searchField?.type==='Number')?
                        numberOptions
                        :(newRule.searchField?.type==='Date')?
                        dateOptions
                        :(newRule.searchField?.type==='Boolean')?
                        booleanOptions
                        :(newRule.searchField?.type==='String')?
                        stringOptions
                        :
                        null
                    } 
                    isDisabled={newRule.searchField?false:true}
                    value={newRule.searchOperator?{value:newRule.searchOperator,label:newRule.searchOperator}:null} 
                    onChange={(e)=>handleChange(e,'searchOperator')}
                 />
            </Col>
            <Col>
                <Form.Label>Value</Form.Label>
                {(newRule.searchField?.lookup!=='None')?
                <ReactSelect options={dynamicValueOptions} value={dynamicDropdownValue} onChange={(e)=>handleChange(e,'searchValueSelect')} />
                :(newRule.searchField?.type==='Number')?
                <Form.Control type='number' value={newRule.searchValue?newRule.searchValue:''} onChange={(e)=>handleChange(e,'searchValue')} />
                :(newRule.searchField?.type==='Date')?
                <Form.Control type='date' value={newRule.searchValue?newRule.searchValue:''} onChange={(e)=>handleChange(e,'searchValue')} />
                :(newRule.searchField?.type==='Boolean')?
                <ReactSelect options={[{value:"True",label:'True'},{value:"False",label:'False'}]} onChange={(e)=>handleChange(e,'selectSearchValue')} />
                :(newRule.searchField?.type==='String')?
                <Form.Control value={newRule.searchValue?newRule.searchValue:''} onChange={(e)=>handleChange(e,'searchValue')} />
                :
                <Form.Control disabled={true} />
                }
            </Col>
            <Col className='advanced-search-button-wrapper'>
                <ButtonGroup>
                    <Button onClick={()=>handleAddRule()} className='full-width'>Add rule</Button>
                    <DropdownButton variant="outline-primary" as={ButtonGroup} title="Saved Searches">
                        {savedSearches?savedSearches.map(search=>{
                            return <Dropdown.Item onClick={()=>populateSavedSearch(search.searchJSON)}>{search.searchName}</Dropdown.Item>

                        })
                        :loadingSavedSearches?
                        <Spinner animation='border' size='sm'/>
                        :
                        <Dropdown.Item>No saved searches</Dropdown.Item>
                        }
                    </DropdownButton>
                </ButtonGroup>
            </Col>
        </Row>
        {postObj.map((obj,index)=>{
            return(
                <Row className='advanced-search-rule-spacing'>
                    <Col>
                        <Form.Control disabled value={obj.searchField} />
                    </Col>
                    <Col>
                        <Form.Control disabled value={obj.searchOperator} />
                    </Col>
                    <Col>
                        <Form.Control disabled value={obj.searchValue} />
                    </Col>
                    <Col>
                        <Form.Control disabled value={obj.searchCondition} />
                    </Col>
                    <Col>
                        <Button onClick={()=>removeRule(index)} variant='danger'>Remove Rule</Button>
                    </Col>
                </Row>
            )
        })}
        {(postObj.length>0)?
        <ButtonGroup className='margin-top'>
            <Button onClick={()=>submitRulesToSearch()}>
                {searchLoading?<Spinner animation='border' size='sm'/>:'Search'}
            </Button>
            <Button variant='outline-primary' onClick={()=>setSaveSearchModalIsOpen(true)}>
                Save Search
            </Button>
        </ButtonGroup>
        :null}
        </>
    )
 }
 