import React from "react";
import Header from "./Header";

export const PageLayout = (props) => {

  return (
    <>
      <Header />
      <div className="content">
        {props.children}
      </div>
    </>
  );
};