import React, { useState } from 'react'
import useFetchGet from '../../Utilities/useFetchGet';
import { AgGridReact } from 'ag-grid-react';
import AccountDetailsPopup from '../../AccountDetailsPopup/AccountDetailsPopup';
import { formatDate } from '../../Utilities/GridTools';
import './NCD.css'
import TakeClaimAssignment from './TakeClaimAssignment';
import CollectorClaimModal from './CollectorClaimModal';

function NCD() {
    const { REACT_APP_API_URL } = process.env;
    const { data } = useFetchGet(`${REACT_APP_API_URL}/Claims/GetNCDList`)
    const [selectedRow, setSelectedRow] = useState();
    const [openPopup,setOpenPopup] = useState(false);
    const [selectedClaim, setSelectedClaim] = useState();
    const [defaultColDef] = useState({
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter'
      })
    const [columnDefs] = useState([
      {maxWidth: 130, cellRenderer: TakeClaimAssignment, headerName:'Assign'},
      {field:'debtorId'},
      {field:'statusCode'},
      {field:'lastPtmAmt',filter: 'agNumberColumnFilter'},
      {field:'lastPtmDt', valueFormatter: formatDate, filter: 'agDateColumnFilter'},
      {field:'nextContactDate', valueFormatter: formatDate, filter: 'agDateColumnFilter', sort:'asc'},
      {field:'collector'},
      {field:'clientName'},
      {field:'debtorStatus'},
      {field:'projectId'},
      {field:'claimNumber'},
      {field:'adjuster'},
      {field:'listDate', valueFormatter: formatDate, filter: 'agDateColumnFilter'},
      {field:'age',filter: 'agNumberColumnFilter'},
      {field:'statusDate', valueFormatter: formatDate, filter: 'agDateColumnFilter'},
      {field:'dateAssigned', valueFormatter: formatDate, filter: 'agDateColumnFilter'}
    ])

    const onCellClicked = (params) => { 
      if((params.colDef.field==='collector'&&params.data.collector.toUpperCase()==='TBD')||params.colDef.headerName==='Assign'){
        setSelectedRow(params.data);
      }else{
        setSelectedRow(params.data);
        setSelectedClaim(params.data.debtorId)
        setOpenPopup(true);
      }
     }

    const onFirstDataRendered = (params) => { 
      params.columnApi.autoSizeAllColumns();
     }

  return (
    <>
    {openPopup&&<AccountDetailsPopup show={openPopup} setShow={setOpenPopup} selectedRow={selectedRow} />}
    <CollectorClaimModal
        show={openPopup} 
        setShow={setOpenPopup} 
        selectedClaim={selectedClaim}
        setSelectedClaim={setSelectedClaim}
        />
    <h2 className='header-text'>NCD</h2>
    <div className="ag-theme-alpine grid">
        <AgGridReact
            multiSortKey='ctrl'
            defaultColDef={defaultColDef}
            rowData={data}
            columnDefs={columnDefs}
            onCellClicked={onCellClicked}
            onFirstDataRendered={onFirstDataRendered}
        ></AgGridReact>
    </div>
    </>
  )
}

export default NCD