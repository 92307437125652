const formatDate = (params) => { 
    if(params.value){
        let dateObj = new Date(params.value)
        return dateObj.toLocaleDateString()
    }else{
        return ''
    }
 }

const formatDollar = (params) => {
    let dollarString = new Intl.NumberFormat("en-US", {
        style: 'currency',
        currency: 'USD',
     });
    if(params.value){
        return dollarString.format(params.value)
    }else{
        return '$0.00'
    }
}

export {
    formatDate,
    formatDollar
}